
import { PropType, defineComponent } from 'vue'

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String as PropType<string>,
            required: false,
            default: () => 'Checkbox'
        },
        modelValue: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => false
        }
    },
    setup(props, { emit }) {
        const onCheckChange = (e: InputEvent) => {
            emit('update:modelValue', (e.target as HTMLInputElement).checked)
        }

        return {
            onCheckChange
        }
    }
})
