<template>
    <svg
        id="Component_394_1"
        data-name="Component 394 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <circle
            id="Ellipse_150"
            data-name="Ellipse 150"
            cx="10"
            cy="10"
            r="10"
            fill="#3a3a3a"
        />
        <circle
            id="Ellipse_151"
            data-name="Ellipse 151"
            cx="1"
            cy="1"
            r="1"
            transform="translate(9 4)"
            fill="#fff"
        />
        <rect
            id="Rectangle_413"
            data-name="Rectangle 413"
            width="2"
            height="8"
            rx="1"
            transform="translate(9 8)"
            fill="#fff"
        />
    </svg>
</template>
