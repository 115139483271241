
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const store = useStore()
        const { t } = useI18n()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const oldPass = ref<string>('')
        const newPass = ref<string>('')
        const confirmPass = ref<string>('')

        const confirm = () => {
            if (oldPass.value === '') {
                message.value = t('passworderror')
                return
            }

            if (newPass.value === '') {
                message.value = t('passworderror')
                return
            }

            if (newPass.value !== confirmPass.value) {
                message.value = t('passworderror')
                return
            }

            message.value = ''

            store.commit(AppMutation.SHOW_LOADING)

            const _params = {
                ...OtherApiParams,
                opwd: oldPass.value,
                npwd: newPass.value,
                username: user.value,
                act: ACT.CHANGE_PASS
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (_data.includes('err=')) {
                        message.value = t('passworderror')
                    } else {
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('modifyok'))

                        if (lastUserName.value && lastUserPwd.value) {
                            store.commit(AppMutation.SET_LAST_USER, {
                                u: lastUserName.value,
                                p: newPass.value
                            })
                        }
                        close()
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('unknowerror'))
                })
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'password'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const user = computed(() => store.getters['user'])

        const lastUserName = computed(() => store.getters['lastUserName'])
        const lastUserPwd = computed(() => store.getters['lastUserPwd'])

        return { oldPass, newPass, confirmPass, message, confirm, close, t }
    }
})
