<template>
    <svg
        id="Component_396_1"
        data-name="Component 396 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <circle
            id="Ellipse_153"
            data-name="Ellipse 153"
            cx="10"
            cy="10"
            r="10"
            fill="#3a3a3a"
        />
        <path
            id="Path_538"
            data-name="Path 538"
            d="M6.538-11.326h.868v1.148a3.5,3.5,0,0,1,2,.7,3.182,3.182,0,0,1,1.064,2.2H8.988A1.793,1.793,0,0,0,8.33-8.568,1.712,1.712,0,0,0,7.406-8.9v3.22l1.778.672A2.56,2.56,0,0,1,10.64-2.688,2.558,2.558,0,0,1,9.534-.448a4.372,4.372,0,0,1-2.128.63V1.414H6.538V.182A3.319,3.319,0,0,1,4.354-.644,3.769,3.769,0,0,1,3.36-3.192H4.872a2.433,2.433,0,0,0,.574,1.624,1.789,1.789,0,0,0,1.092.462V-4.522L4.956-5.11a2.5,2.5,0,0,1-1.47-2.324,2.4,2.4,0,0,1,1.05-2.1,3.527,3.527,0,0,1,2-.644Zm0,2.408a1.859,1.859,0,0,0-.952.308,1.288,1.288,0,0,0-.574,1.176q0,.854,1.47,1.4l.056.028Zm.868,7.812a2.7,2.7,0,0,0,1.12-.378,1.35,1.35,0,0,0,.6-1.148q0-.98-1.47-1.484L7.406-4.2Z"
            transform="translate(3 15)"
            fill="#fff"
        />
    </svg>
</template>
