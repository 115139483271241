<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18.001"
        viewBox="0 0 20 18.001"
    >
        <g
            id="Component_350_2"
            data-name="Component 350 – 2"
            transform="translate(0 0)"
        >
            <path
                id="Path_452"
                data-name="Path 452"
                d="M-9136.588-3787a2.4,2.4,0,0,1-2.4-2.4v-2h1.6v2a.8.8,0,0,0,.8.8h11.19a.8.8,0,0,0,.8-.8v-13.2a.8.8,0,0,0-.8-.8h-11.19a.8.8,0,0,0-.8.8v2h-1.6v-2a2.4,2.4,0,0,1,2.4-2.4h11.19a2.4,2.4,0,0,1,2.4,2.4v13.2a2.4,2.4,0,0,1-2.4,2.4Zm-2.921-5.452-3.233-2.99a.8.8,0,0,1-.257-.591.789.789,0,0,1,.264-.589l3.232-2.933a.8.8,0,0,1,1.129.055.8.8,0,0,1-.055,1.131l-1.692,1.535h8.056a.8.8,0,0,1,.8.8.8.8,0,0,1-.8.8h-8.1l1.738,1.608a.8.8,0,0,1,.047,1.131.8.8,0,0,1-.587.257A.793.793,0,0,1-9139.509-3792.451Z"
                transform="translate(9142.999 3805)"
                fill="#3a3a3a"
            />
        </g>
    </svg>
</template>
