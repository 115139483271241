<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
    >
        <g
            id="Group_496"
            data-name="Group 496"
            transform="translate(-9309 -961)"
        >
            <circle
                id="Ellipse_156"
                data-name="Ellipse 156"
                cx="6"
                cy="6"
                r="6"
                transform="translate(9309 961)"
                fill="#a8a8a8"
            />
            <path
                id="Path_681"
                data-name="Path 681"
                d="M191.944,68.265h-1.917a.525.525,0,0,1-.412-.224.971.971,0,0,1-.174-.452.716.716,0,0,1,.174-.407.59.59,0,0,1,.412-.179h1.917v-.626l-1.917-.01a.571.571,0,0,1-.412-.189.778.778,0,0,1-.174-.427.716.716,0,0,1,.174-.407.59.59,0,0,1,.412-.179h1.431l-1.331-2.345a1.494,1.494,0,0,1-.189-.3.82.82,0,0,1-.079-.4.931.931,0,0,1,.2-.452.856.856,0,0,1,.556-.224.8.8,0,0,1,.427.164,1.446,1.446,0,0,1,.308.313l1.51,2.762,1.659-2.782a1.2,1.2,0,0,1,.308-.3.954.954,0,0,1,.427-.159,1.64,1.64,0,0,1,.273.04.6.6,0,0,1,.219.1.541.541,0,0,1,.159.2,1.118,1.118,0,0,1,.094.338.882.882,0,0,1-.2.546l-1.54,2.5h1.46a.6.6,0,0,1,.407.179.651.651,0,0,1,.169.407.737.737,0,0,1-.174.437.579.579,0,0,1-.412.2l-1.9.01V67h1.907a.562.562,0,0,1,.407.2.742.742,0,0,1,.169.427.731.731,0,0,1-.169.432.569.569,0,0,1-.407.194l-1.907-.01V69.3a.806.806,0,0,1-.924.844,1.014,1.014,0,0,1-.681-.209.847.847,0,0,1-.263-.636Z"
                transform="translate(9122.133 901.209)"
                fill="#fff"
            />
        </g>
    </svg>
</template>
