<template>
    <svg
        id="Component_400_1"
        data-name="Component 400 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12.001"
        viewBox="0 0 12 12.001"
    >
        <path
            id="Subtraction_59"
            data-name="Subtraction 59"
            d="M76-49a6.007,6.007,0,0,1-6-6,6.007,6.007,0,0,1,6-6,6.007,6.007,0,0,1,6,6A6.007,6.007,0,0,1,76-49Zm-2.84-4.788a.589.589,0,0,0-.412.178.726.726,0,0,0-.175.408.984.984,0,0,0,.175.452.523.523,0,0,0,.412.223h1.917v1.033a.852.852,0,0,0,.264.636,1.019,1.019,0,0,0,.68.209.808.808,0,0,0,.923-.845v-1.053l1.908.01a.567.567,0,0,0,.407-.194.734.734,0,0,0,.169-.432.747.747,0,0,0-.169-.427.56.56,0,0,0-.407-.2H76.944v-.607l1.9-.01a.581.581,0,0,0,.412-.2.74.74,0,0,0,.174-.437.655.655,0,0,0-.169-.408.6.6,0,0,0-.407-.179H77.391l1.54-2.5a.889.889,0,0,0,.2-.546,1.122,1.122,0,0,0-.095-.338.542.542,0,0,0-.159-.2.586.586,0,0,0-.218-.1,1.627,1.627,0,0,0-.274-.04.956.956,0,0,0-.428.159,1.2,1.2,0,0,0-.308.3l-1.659,2.782-1.51-2.762a1.468,1.468,0,0,0-.308-.313.8.8,0,0,0-.427-.164.862.862,0,0,0-.556.223.936.936,0,0,0-.2.453.829.829,0,0,0,.079.4,1.547,1.547,0,0,0,.189.3l1.331,2.345h-1.43a.591.591,0,0,0-.412.179.726.726,0,0,0-.175.408.786.786,0,0,0,.175.427.573.573,0,0,0,.412.189l1.917.01v.626Z"
            transform="translate(-70 61)"
            fill="#e6c361"
        />
    </svg>
</template>
