
import { defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
    emits: ['update:value'],
    props: {
        value: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => true
        }
    },
    setup(props, { emit }) {
        const emitEvent = (e: Event) => {
            emit('update:value', (e.target as HTMLInputElement).checked)
        }

        return {
            emitEvent
        }
    }
})
