
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { SVGArrow } from '@/assets/svg'
import { BetLogDeatails } from '@/types/BetLogDetails'
import { BetLog } from '@/types/BetLog'
import { formatDate, subtractDaysFromDate } from '@/utils'
// import { CTRLDropdown } from '@/components/custom-controls'

export default defineComponent({
    components: {
        'icon-arrow': SVGArrow
        // 'dropdown': CTRLDropdown
    },
    setup() {
        const store = useStore()
        const { t } = useI18n()

        const emitter = inject('emitter') as Emitter

        const gameNo = ref('')
        const dateValue = ref({
            start: subtractDaysFromDate(new Date(), 1),
            end: new Date()
        })
        const masks = ref({
            input: 'YYYY-MM-DD'
        })

        const isDetailView = ref(false)

        const betLogDetails = ref<Array<BetLogDeatails>>([])
        const betLogs = ref<Array<BetLog>>([])

        const selectedGameId = ref('')
        const showVideo = ref(false)

        const minDate = () => {
            const days = 45
            const date = new Date()
            const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
            const day = last.getDate()
            const month = last.getMonth() + 1
            const year = last.getFullYear()
            return new Date(`${month}/${day}/${year}`)
        }

        onMounted(() => {
            doSearch()
        })

        const doSearch = () => {
            if (!dateValue.value) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('datesinvalid'))
                return
            }

            const _param = {
                ...OtherApiParams,
                act: ACT.GET_LOG,
                username: user.value,
                sday: dateValue.value.start.toISOString().substr(0, 10),
                eday: dateValue.value.end.toISOString().substr(0, 10)
            }

            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (_data.includes('err=')) {
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                    } else {
                        betLogs.value = BetLog.ParseList(_data)
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('网络错误'))
                })
        }

        const OnClickLogsItem = (_mday: string) => {
            betLogDetails.value = []
            isDetailView.value = true

            const _param = {
                ...OtherApiParams,
                act: ACT.GET_LOG_DETAILS,
                sday: _mday,
                username: user.value
            }

            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (_data.includes('err=')) {
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                    } else {
                        betLogDetails.value = BetLogDeatails.ParseList(_data)
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                })
        }

        const confirm = () => {
            //
        }

        const roundFixed2 = (_value = 0) => {
            return +_value.toFixed(2)
        }

        const close = () => {
            if (!isDetailView.value) {
                const _evt: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: 'record'
                }
                emitter.emit(EventBuss.DIALOG, _evt)
            } else isDetailView.value = false
        }

        const closeVideo = () => {
            showVideo.value = false
            selectedGameId.value = ''
        }

        const previewGame = (gameId: string) => {
            selectedGameId.value = gameId
            showVideo.value = true
        }

        const user = computed(() => store.getters['user'])

        const showVideoPlayer = computed(() => {
            return showVideo.value && selectedGameId.value !== ''
        })

        const videoSoure1 = computed(() => {
            if (selectedGameId.value !== '') {
                const parsedURL = new URL(process.env.VIDEO_URL1)
                parsedURL.port = ''
                const videoUrl = parsedURL.toString()

                return `${videoUrl}${selectedGameId.value}.mp4`
            }

            return ''
        })

        const videoSoure2 = computed(() => {
            if (selectedGameId.value !== '') {
                const parsedURL = new URL(process.env.VIDEO_URL2)
                parsedURL.port = ''
                const videoUrl = parsedURL.toString()

                return `${videoUrl}${selectedGameId.value}.mp4`
            }

            return ''
        })

        return {
            gameNo,
            masks,
            dateValue,
            isDetailView,
            betLogDetails,
            betLogs,
            OnClickLogsItem,
            roundFixed2,
            formatDate,
            doSearch,
            minDate,
            confirm,
            close,
            t,
            closeVideo,
            previewGame,
            videoSoure1,
            videoSoure2,
            showVideoPlayer
        }
    }
})
