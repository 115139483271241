<template>
    <svg
        id="Component_395_1"
        data-name="Component 395 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="19.999"
        height="17.674"
        viewBox="0 0 19.999 17.674"
    >
        <path
            id="Union_30"
            data-name="Union 30"
            d="M-10417.628-831.512l.616-3.272a7.115,7.115,0,0,1-2.418-5.216c0-4.419,4.477-8,10-8s10,3.58,10,8-4.477,8-10,8a12.394,12.394,0,0,1-2.594-.272,1,1,0,0,1-.306.2l-3.926,1.666a1,1,0,0,1-.392.081A1,1,0,0,1-10417.628-831.512Z"
            transform="translate(10419.43 847.999)"
            fill="#3a3a3a"
        />
        <rect
            id="Rectangle_414"
            data-name="Rectangle 414"
            width="12"
            height="1"
            rx="0.5"
            transform="translate(4 5.999)"
            fill="#fff"
        />
        <rect
            id="Rectangle_415"
            data-name="Rectangle 415"
            width="3"
            height="1"
            rx="0.5"
            transform="translate(4 9.999)"
            fill="#fff"
        />
        <rect
            id="Rectangle_416"
            data-name="Rectangle 416"
            width="8"
            height="1"
            rx="0.5"
            transform="translate(8 9.999)"
            fill="#fff"
        />
    </svg>
</template>
