import request from '@/utils/request'
import axios, { AxiosPromise } from 'axios'

export const AxiosSource = { Source: axios.CancelToken.source() }
/**
 * Api Call
 * @param params
 * @returns
 */
export function fetch(params: any): AxiosPromise {
    return request({
        method: 'POST',
        params,
        cancelToken: AxiosSource.Source.token,
        responseType: 'arraybuffer'
    })
}
