<template>
    <svg
        id="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="19.5"
        height="16.404"
        viewBox="0 0 19.5 16.404"
    >
        <path
            id="icon-2"
            data-name="icon"
            d="M58.836,169.355v12.717a.432.432,0,0,1-.8.258c-1.333-2.226-2.649-3.394-7.272-3.641l1.633,5.659a.743.743,0,0,1-.85.939,1.261,1.261,0,0,1-1.255-.939l-1.725-5.717a2.935,2.935,0,0,1,.126-5.844c6.34,0,7.83-1.163,9.344-3.692A.432.432,0,0,1,58.836,169.355Z"
            transform="translate(-45.966 -168.885)"
            fill="#3a3a3a"
        />
        <path
            id="Path_536"
            data-name="Path 536"
            d="M0,0A4.413,4.413,0,0,1,2,4,4.413,4.413,0,0,1,0,8"
            transform="translate(14 3.233)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="1"
        />
        <path
            id="Path_537"
            data-name="Path 537"
            d="M0-2.3A6.953,6.953,0,0,1,3,3.715,6.856,6.856,0,0,1,0,9.7"
            transform="translate(16 3.536)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="1"
        />
    </svg>
</template>
