<template>
    <svg
        id="Component_37_2"
        data-name="Component 37 – 2"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g
            id="Ellipse_95"
            data-name="Ellipse 95"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="1"
        >
            <circle cx="10" cy="10" r="10" stroke="none" />
            <circle cx="10" cy="10" r="9.5" fill="none" />
        </g>
        <g
            id="Ellipse_96"
            data-name="Ellipse 96"
            transform="translate(3.333)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="1"
        >
            <ellipse cx="6.333" cy="10" rx="6.333" ry="10" stroke="none" />
            <ellipse cx="6.333" cy="10" rx="5.833" ry="9.5" fill="none" />
        </g>
        <path
            id="Path_534"
            data-name="Path 534"
            d="M0,0H17.6"
            transform="translate(1.2 10.333)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="1"
        />
        <path
            id="Path_535"
            data-name="Path 535"
            d="M0,0V17.6"
            transform="translate(9.667 1.2)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-width="1"
        />
        <path
            id="Path_190"
            data-name="Path 190"
            d="M16.983,24.355a9.174,9.174,0,0,1-6.2,2.358,9.421,9.421,0,0,1-6.437-2.574"
            transform="translate(-0.782 -21.379)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="1"
        />
        <path
            id="Path_192"
            data-name="Path 192"
            d="M16.983,26.5a9.174,9.174,0,0,0-6.2-2.358,9.421,9.421,0,0,0-6.437,2.575"
            transform="translate(-0.782 -9.379)"
            fill="none"
            stroke="#3a3a3a"
            stroke-width="1"
        />
    </svg>
</template>
